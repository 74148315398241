import InfoBaseHighLight from "public/images/style-match/infobase-highlight.png";
import PrebuiltToolbar from "public/images/generator/prebuilt-toolbar.png";
import Video from "public/images/citation/video.png";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import InteractiveCommand from "public/images/generator/interactive-command.png";
import {
  serverRoutes,
  templateServerRouteBySlug,
} from "client-server-shared/config/routes";
import VoiceChatIcon from "@mui/icons-material/VoiceChat";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import askforRaisePic from "public/images/email/ask-for-raise.png";
import campaignPic from "public/images/marketing/campaign.png";
import generativeAiPic from "public/images/blog/generative-ai.png";
import launchProductPic from "public/images/social-media/launching-product.png";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import QuickreplyOutlinedIcon from "@mui/icons-material/QuickreplyOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import ChromeReaderModeOutlinedIcon from "@mui/icons-material/ChromeReaderModeOutlined";
import { WordPressIcon } from "client-server-shared/icons/word-press-icon";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";

import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";

import { isCn, locale } from "client-server-shared/constants";
import { withHtmlLink } from "utils/html-link";

import instagramBioImage1 from "public/images/social-media/blog-post-1.png";
import instagramBioImage2 from "public/images/social-media/blog-post-2.png";
import instagramBioImage3 from "public/images/social-media/blog-post-3.png";

export const useStepData = () => {
  if (isCn()) {
    return {
      steps: [
        {
          type: "image",
          stepNumber: "01",
          headline: "选择你想要创建的写作类型",
          description: `我们的工具提供了多种平台供你选择，包括电子邮件，社交媒体帖子，长篇博客文章等，使你能够根据每个特定平台定制你的内容。`,
          imageUrl: instagramBioImage1,
          imageAlt: "在Junia的内容生成器中选择要写的内容类型",
        },
        {
          stepNumber: "02",
          type: "image",
          headline: "定制你的帖子主题和语气",
          description: `输入你的帖子主题和期望的语气，我们将为你创作符合你需求的吸引人和符合品牌的内容`,
          imageUrl: instagramBioImage2,
          imageAlt: `在Junia的内容生成器中定制内容的主题和语气`,
        },
        {
          type: "image",
          stepNumber: "03",
          headline: "生成多种变体",
          description: `通过我们的工具轻松生成你内容的多种变体，确保你有符合品牌声音和风格的选项可供选择`,
          imageUrl: instagramBioImage3,
          imageAlt: `在Junia的内容生成器中生成内容的多种变体`,
        },
      ],
      finalStep: {
        stepNumber: "04",
        headline: "编辑，审查和发布",
        description: `使用我们的编辑器重写段落，释义，编辑和修改句子。`,
      },
    };
  }
  return {
    steps: [
      {
        type: "image",
        stepNumber: "01",
        headline: "Enter topic and keywords of your blog post",
        description: `Optionally, you can also input your competitors' URLs. Our AI will analyze and comprehend the factors contributing to their higher rankings, then generate content with a superior SEO ranking.`,
        imageUrl: instagramBioImage1,
        imageAlt: "Enter topic and keywords of blog post",
      },
      {
        stepNumber: "02",
        type: "image",
        headline: "Customize Tone of Voice and Content Output",
        description: `Enter your desired tone of voice and any additional instructions regarding how you want your content to be written. Our AI will generate content that suits your brand's voice and style. Furthermore, select a word count for your article. Our AI is capable of generating content up to 6000 words in one go.`,
        imageUrl: instagramBioImage2,
        imageAlt: `Customize Tone of Voice and Content Output`,
      },
      {
        type: "image",
        stepNumber: "03",
        headline: "Generate The Final Blog Post",
        description: `Click the "Generate" button, and our AI will write a long-form blog post that is truly SEO-optimized and plagiarism-free for your chosen blog topic.`,
        imageUrl: instagramBioImage3,
        imageAlt: `Click the "Generate" button to have AI write a long-form blog post that is truly SEO-optimized and plagiarism-free.`,
      },
    ],
    finalStep: {
      stepNumber: "04",
      headline: "Edit, review, and publish",
      description: `Use our AI Content Editor to revise paragraphs, paraphrase text, enhance depth, and further increase the word count of your article.`,
    },
  };
};

export const useFeatureList = () => {
  if (locale === "cn") {
    return [
      {
        type: "image",
        title: "人工智能联合写作",
        imageUrl: InteractiveCommand,
        url: serverRoutes.editor,
        icon: HistoryEduOutlinedIcon,
        description:
          "让我们的AI加快您的写作速度，在一个标签页中获取创新且符合SEO的标题、大纲和主题！",
      },
      {
        title: "与任何文件聊天",
        description:
          "我们为你提供优质的文件分析服务，让你可以与任何文件聊天，秒钟之内充分利用你的数据。",
        type: "video",
        videoUrl: "/videos/ai-chat/chat-with-files.mp4",
        url: serverRoutes.filechat,
        icon: ArticleOutlinedIcon,
      },
      {
        type: "image",
        imageUrl: InfoBaseHighLight,
        title: "个性化AI配合风格匹配",
        url: serverRoutes.styleMatch,
        icon: VoiceChatIcon,
        description:
          "Junia可以被训练去模仿你的写作风格和记住你或你的公司的信息。就像有个私人助手，比任何人都更了解你。",
      },
      {
        type: "image",
        title: "强大的改写工具",
        imageUrl: PrebuiltToolbar,
        url: serverRoutes.editor,
        icon: AutoAwesomeOutlinedIcon,
        description:
          "在保持适合任何情况的词汇、语调和风格的同时，重述、延长、缩短或总结任何文本。",
      },
      {
        type: "image",
        title: "引文生成器",
        imageUrl: Video,
        url: serverRoutes.citation,
        icon: FormatQuoteOutlinedIcon,
        description: "在几秒钟内为网页、书籍、视频和期刊文章生成引文。",
      },
      {
        type: "video",
        url: serverRoutes.aiDetector,
        icon: ContentPasteSearchOutlinedIcon,
        title: "人工智能内容检测器",
        videoUrl: "/videos/generator/ai-detector.mp4",
        description: "使用我们内置的AI内容检测器评估你的内容。",
      },
    ];
  }
  return [
    {
      type: "image",
      title: "AI Co-Writer",
      imageUrl: InteractiveCommand,
      url: serverRoutes.editor,
      icon: HistoryEduOutlinedIcon,
      description:
        "Let our AI speed up your copywriting, receive creative and SEO-friendly headlines, outlines and topics all in one tab!",
    },
    {
      title: "File Data Analysis",
      description:
        "We offer superior file analysis for you to chat with any files, getting the most out of your data in seconds.",
      type: "video",
      videoUrl: "/videos/ai-chat/chat-with-files.mp4",
      url: serverRoutes.filechat,
      icon: ArticleOutlinedIcon,
    },
    {
      type: "image",
      imageUrl: InfoBaseHighLight,
      title: "Personalized AI with brand voice",
      url: serverRoutes.styleMatch,
      icon: VoiceChatIcon,
      description:
        "Our AI can be trained to mimic your writing styles and memorize you or your company info. Ensure your articles remain consistent with your brand voice throughout.",
    },
    {
      type: "image",
      title: "Powerful paraphrasing tools",
      imageUrl: PrebuiltToolbar,
      url: serverRoutes.editor,
      icon: AutoAwesomeOutlinedIcon,
      description:
        "Rephrase, lengthen, shorten or summarize any text while consistently maintaining the vocabulary, tone, and style appropriate for every circumstance.",
    },
    {
      type: "image",
      title: "Citation Generator",
      imageUrl: Video,
      url: serverRoutes.citation,
      icon: FormatQuoteOutlinedIcon,
      description:
        "Generate citations for webpages, books, videos, and journal articles in seconds.",
    },
    {
      type: "video",
      url: serverRoutes.aiDetector,
      icon: ContentPasteSearchOutlinedIcon,
      title: "AI content detector",
      videoUrl: "/videos/generator/ai-detector.mp4",
      description:
        "Assess the proportion of your content that is perceived as being created by a human with our built-in AI content detector.",
    },
    /*
        {
          title: "Powerful AI Content Editor",
          description:
            "Generate long form article within one click that Google will love! Powered built-in AI Image generator, Paraphraser, Co-Writer, Summarizer, Citation Generator and more to ensure that every word is precisely crafted to perfection.",
          type: "image",
          imageUrl: InteractiveCommand,
        },
        */
  ];
};

export const useMainFeatureList = () => {
  if (locale === "cn") {
    return [
      {
        value: "blogs",
        headline: "使用俊雅快速编写10倍的SEO优化博客文章",
        subheadline: "借助人工智能轻松克服写作障碍，并轻松启动您的文章。",
        content: [
          {
            icon: SourceOutlinedIcon,
            title: "",
            description: `使用我们的${withHtmlLink(
              "博客文章工作流",
              templateServerRouteBySlug("blog")
            )}快速创建原创且无抄袭的长篇内容。`,
          },
          {
            icon: WordPressIcon,
            title: "改造你现有的内容",
            description:
              "使用在其他平台（如Contentful，Sanity，Google Docs和WordPress等）中的Junia改善你现有的内容",
          },
          {
            icon: GradingOutlinedIcon,
            title: "",
            description:
              "最大限度地提高生产力，使用批量模式快速创建吸引人的内容，这是一种胜过竞争对手的人工智能解决方案。",
          },
        ],
        description: `
            <p>✔ 使用人工智能轻松克服写作障碍并轻松启动你的文章。</p>
            <p>✔ 使用我们的博客文章工作流快速创建原创且无抄袭的长篇内容。</p>
            <strong>✔ 改造你现有的内容 </strong>
            <p>✔ 使用在其他平台（如Contentful，Sanity，Google Docs和WordPress等）中的Junia改善你现有的内容。</p>
            <p>✔ 使用批量模式最大限度地提高生产力，快速创建吸引人的内容，这是一种胜过竞争对手的人工智能解决方案。</p>
            `,
        imageUrl: generativeAiPic,
        imageAlt:
          "展示一篇使用Junia's文章编辑器编写的关于如何使用AI提高生产力的文章",
        additionalList: [],
      },
      {
        value: "advertising",
        headline: "释放你的营销潜力",
        content: [
          {
            icon: ContentPasteOutlinedIcon,
            title: "",
            description: "快速且容易地创建有说服力的广告文案",
          },
          {
            icon: ViewInArOutlinedIcon,
            title: "",
            description: `可以访问到广泛的${withHtmlLink(
              "模板",
              serverRoutes.templates
            )}，样式和格式，以创建视觉上引人入胜和吸引人的广告`,
          },
          {
            icon: CreditScoreOutlinedIcon,
            title: "",
            description: "减少与聘用设计师和文案写手相关的费用",
          },
        ],
        description: `<p>✔ 快速且容易地创建有说服力的广告文案</p>
          <p>✔ 快速且容易地生成多种 广告变体</p>
          <p>✔ 可以访问到广泛的模板，样式和格式，以创建视觉上引人入胜和吸引人的广告</p>
          <p>✔ 减少与聘用设计师和文案写手相关的费用</p>`,
        imageUrl: campaignPic,
        imageAlt: "展示了如何使用Junia AI编写影响者营销活动的文章",
        additionalList: [],
      },
      {
        value: "emails",
        headline: "在几秒钟内写出有说服力的电子邮件。",
        content: [
          {
            icon: EmailOutlinedIcon,
            title: "",
            description: "生成高质量的电子邮件主题行",
          },
          {
            icon: ManageAccountsOutlinedIcon,
            title: "",
            description: `编写个性化的${withHtmlLink(
              "销售电子邮件",
              templateServerRouteBySlug("cold-email-email")
            )}`,
          },
          {
            icon: QuickreplyOutlinedIcon,
            title: "",
            description: `使用友好的语气快速回应消息，评论，${withHtmlLink(
              "评价",
              templateServerRouteBySlug("review-responder")
            )}和支持票据。`,
          },
        ],
        description: ``,
        imageUrl: askforRaisePic,
        imageAlt: "展示了如何使用Junia AI写出请求加薪的电子邮件",
        additionalList: [],
      },

      {
        value: "socialmedia",
        subheadline:
          "再也不需要为创作困扰了 - Junia 可以帮助你激发灵感，轻松创作内容。",
        headline: "快速创作社交帖子",
        content: [
          {
            icon: ScheduleOutlinedIcon,
            title: "更快的内容制作",
            description:
              "加快你的写作流程，用极少的时间创作出10倍的社交媒体帖子。",
          },
          {
            icon: EqualizerOutlinedIcon,
            title: "增加关注者",
            description: "创建吸引人的社交媒体帖子，促进参与和增长。",
          },
          {
            icon: ChromeReaderModeOutlinedIcon,
            title: "工具和模板",
            description: "使用超过50+个可定制的工具和模板简化内容创作。",
          },
        ],
        description:
          "<p>你是否觉得每天都需要为你的社交媒体帖子想出新主意很有挑战性？</p> <p>再也不需要为创作困扰了 - Junia 可以帮助你激发灵感，轻松创作内容。</p>",
        imageUrl: launchProductPic,
        imageAlt: "使用Junia AI Chat发布产品的社交媒体帖子",
        additionalList: [],
      },
    ];
  }
  return [
    {
      value: "blogs",
      headline: "Write Authentic Blog Posts 10x Faster",
      subheadline: "Generative AI, doesn't have to be generic.",
      content: [
        {
          icon: SourceOutlinedIcon,
          title: "",
          description: `Create original and plagiarism-free long-form content that's ${withHtmlLink(
            "always true to you",
            serverRoutes.styleMatch
          )}.`,
        },
        {
          icon: ContentPasteOutlinedIcon,
          title: "Ensure the authenticity of your article",
          description:
            "Balance SEO optimization with human-friendly content to avoid potential penalties from search engine.",
        },
        {
          icon: GradingOutlinedIcon,
          title: "",
          description: `Express Your Unique Voice with AI, let AI help you shape content that truly reflects your ideas and personality.`,
        },
      ],
      description: `
          <p>✔ Overcome writer's block and kickstart your articles effortlessly with the help of AI.</p>
          <p>✔ Create original and plagiarism-free long-form content quickly in your own words</p>
          <strong>✔ Repurpose your existing content </strong>
          <p>✔ Improve your existing content with AI that lives in other platforms such as Contentful, Sanity, Google Docs, and WordPress etc</p>
          <p>✔ Maximize your productivity and create captivating content quickly with our rich text content editor, the AI-powered solution that outperforms the competition.</p>
          `,
      imageUrl: generativeAiPic,
      imageAlt:
        "Showcase an article on using AI on increasing productivity written using Junia's article editor",
      additionalList: [],
    },
    {
      value: "advertising",
      headline: "Unlock Your Blogging Potential",
      content: [
        {
          icon: ContentPasteOutlinedIcon,
          title: "",
          description:
            "Get access to a wide range of AI writing tools. Produce more content in less time",
        },
        {
          icon: ViewInArOutlinedIcon,
          title: "",
          description: `Access to a wide range of AI writing tools, styles, and formats to create visually appealing and engaging articles`,
        },
        {
          icon: CreditScoreOutlinedIcon,
          title: "",
          description:
            "Reduce costs associated with hiring designers and copywriters",
        },
      ],
      description: `<p>✔ Create persuasive ad copy quickly and easily</p>
        <p>✔ Generate multiple ad variations quickly and easily</p>
        <p>✔ Access to a wide range of templates, styles, and formats to create visually appealing and engaging ads</p>
        <p>✔ Reduce costs associated with hiring designers and copywriters</p>`,
      imageUrl: campaignPic,
      imageAlt:
        "Showcase of an article on how to write a influencer marketing campaign using Junia AI",
      additionalList: [],
    },
    {
      value: "emails",
      headline: "Write persuasive emails in seconds.",
      content: [
        {
          icon: EmailOutlinedIcon,
          title: "",
          description: "Generate high-quality email subject lines in seconds",
        },
        {
          icon: ManageAccountsOutlinedIcon,
          title: "",
          description: `Write personalized ${withHtmlLink(
            "sales emails",
            templateServerRouteBySlug("cold-email-email")
          )} that converts.`,
        },
        {
          icon: QuickreplyOutlinedIcon,
          title: "",
          description: `Quickly respond to messages, comments, ${withHtmlLink(
            "reviews",
            templateServerRouteBySlug("review-responder")
          )}, and support tickets with a friendly tone.`,
        },
      ],
      description: ``,
      imageUrl: askforRaisePic,
      imageAlt: "Showcase of an email on how to ask for a raise using Junia AI",
      additionalList: [],
    },

    {
      value: "socialmedia",
      subheadline:
        "Never suffer from writer's block again - Junia helps you spark ideas and create content effortlessly.",
      headline: "Create Social Posts in seconds",
      content: [
        {
          icon: ScheduleOutlinedIcon,
          title: "Faster content production",
          description:
            "Speed up your writing process and create 10x more social media posts in a fraction of the time.",
        },
        {
          icon: EqualizerOutlinedIcon,
          title: "Grow your following",
          description: `Create attention-grabbing ${withHtmlLink(
            "social media posts",
            templateServerRouteBySlug("facebook-post")
          )} that drive engagement and growth.`,
        },
        {
          icon: ChromeReaderModeOutlinedIcon,
          title: "Tools and templates",
          description:
            "Simplify content creation with over 50+ customizable tools and templates.",
        },
      ],
      description:
        "<p>Do you find it challenging to come up with new ideas for your social media posts every day?</p> <p>Never suffer from writer's block again - Junia helps you spark ideas and create content effortlessly.</p>",
      imageUrl: launchProductPic,
      imageAlt:
        "A social media post on launching a product using Junia AI Chat",
      additionalList: [],
    },
  ] as const;
};
