import React from "react";
import { Box } from "components/ui/box";
import { Typography } from "components/ui/Typography";
import Balancer from "react-wrap-balancer";
import { headerHeight } from "modules/non-protected-page/shared";
import {
  Container,
  ResponsiveText,
  headlineSizeConfig,
} from "modules/marketing-pages/front-page/shared";
import HowItWorks from "modules/marketing-pages/front-page/how-it-works";
import ReadyToLevelUp from "modules/marketing-pages/ready-to-level-up";
import "react-multi-carousel/lib/styles.css";
import { Grid } from "components/ui/grid";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import {
  dashboardRouteConfig,
  serverRoutes,
} from "client-server-shared/config/routes";
import MoreFeatureList from "modules/marketing-pages/front-page/more-feature-list";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { isCn, locale } from "client-server-shared/constants";
import { Trans, useTranslation } from "components/translate";
import { IndexPageHeadTags } from "common/head-tags";
import {
  HighlightedCallToAction,
  PrimaryCallToAction,
} from "modules/marketing-pages/call-to-action";
import Image from "next/image";
import DescriptionIcon from "@mui/icons-material/Description";
import AIFace1 from "public/materials/ai-face-1.png";
import AIFace2 from "public/materials/ai-face-2.png";
import AIFace3 from "public/materials/ai-face-3.png";
import JuniaBlog from "public/materials/junia-blog.png";
import JuniaBlogMobile from "public/materials/junia-blog-mobile.png";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ContentTypes from "public/materials/content-types.png";
import { BlackButton, Button } from "components/ui/button";
import { Avatar } from "components/ui/avatar";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import StorefrontIcon from "@mui/icons-material/Storefront";
import DraftsIcon from "@mui/icons-material/Drafts";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import { FeatureItem } from "modules/non-protected-page/feature-list";
import JuniaChat from "public/materials/junia-chat.png";
import { NextLink } from "components/next-link";
import { templateServerRouteBySlug } from "client-server-shared/config/routes";
import Testmonial from "modules/marketing-pages/front-page/testmonial";
import Amazing from "modules/marketing-pages/front-page/amazing";
import Script from "next/script";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { HighlightedSpan, InlineSpan } from "components/as-inline-span";
import { AutoPlayVideo } from "components/video/auto-play-video";
import { Features } from "modules/marketing-pages/front-page/features";
import Faq, {
  useFaqList,
} from "modules/marketing-pages/front-page/front-page-faq";
import { buildJsonLd } from "modules/marketing-pages/faq-to-json-ld";
import { ReviewMarkup } from "modules/marketing-pages/testmonial-list";
import { WriterList } from "modules/auth/image-list";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Stars } from "modules/marketing-pages/front-page/stars";
import writeLogo from "public/icons/write-logo.png";
import { GetServerSidePropsContext } from "next";

const AboutJunia = () => {
  const { t } = useTranslation("front-page");
  return (
    <Container
      maxWidth="md"
      component="section"
      sx={{
        paddingTop: "48px",
        paddingBottom: "48px",
      }}
    >
      <Testmonial />
    </Container>
  );
};

const HumanCard = ({
  image,
  alt,
  text,
  extraText,
}: {
  image: string;
  text: string;
  alt: string;
}) => {
  return (
    <Box
      component="figure"
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Image src={image} alt={alt} height={60} width={60} />
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          padding: "16px 24px",
          borderRadius: "15px",
          position: "relative",
          opacity: 0.8,
          marginRight: "24px",
          "&:before": {
            content: "''",
            position: "absolute",
            width: 0,
            height: 0,
            borderWidth: "10px",
            top: "5px",
            right: "-5px",
            border: "21px solid transparent",
            borderBottomColor: "#FFFFFF",
            borderRight: "0px solid",
            borderTop: 0,
            marginTop: "-17px",
            transform: "rotate(24deg)",
          },
        }}
      >
        <Typography color="#000000" component="figcaption">
          {text}
        </Typography>
      </Box>
      {extraText && (
        <Box
          sx={{
            position: "relative",
            marginRight: "24px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              padding: "16px 24px",
              position: "relative",
              display: "flex",
              borderRadius: "15px",
              backgroundColor: "#FFFFFF",
              opacity: 0.8,

              alignItems: "center",
              justifyContent: "space-between",
              "&:before": {
                content: "''",
                position: "absolute",
                width: 0,
                height: 0,
                borderWidth: "10px",
                top: "5px",
                right: "-5px",

                border: "21px solid transparent",
                borderBottomColor: "#FFFFFF",
                borderRight: "0px solid",
                borderTop: 0,
                marginTop: "-17px",
                transform: "rotate(24deg)",
              },
            }}
          >
            <Typography color="#000000" component="figcaption">
              {extraText}
            </Typography>
            <DescriptionIcon
              sx={{
                marginLeft: "12px",
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const HumanCardLists = () => {
  const { t } = useTranslation("front-page");
  return null;
  return (
    <Box
      sx={{
        position: "absolute",
        right: 0,
        top: "0",
        display: {
          xs: "none",
          sm: "none",
          md: "none",
          lg: "initial",
        },
      }}
    >
      <AutoPlayVideo
        style={{
          borderRadius: "22px",
        }}
        controls={false}
        videoUrl="/chat1.mp4"
      />
    </Box>
  );
  return (
    <Box
      sx={{
        position: "absolute",
        right: 0,
        top: "30%",
        display: {
          xs: "none",
          sm: "none",
          md: "none",
          lg: "initial",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <HumanCard
          position="right"
          image={AIFace2}
          alt={t("A happy marketer who uses Junia AI for file analysis")}
          text={t("Summarize this file")}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          marginRight: "80px",
          marginTop: "24px",
        }}
      >
        <Box
          component="figure"
          sx={{
            display: "flex",
            rowGap: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "-35px",
            }}
          >
            <Image
              alt={t(
                "A happy writer who uses Junia AI to write recurring newsletter"
              )}
              src={AIFace1}
              height={60}
              width={60}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              padding: "16px 24px",
              opacity: 0.8,
              borderRadius: "15px",
              position: "relative",
              marginLeft: "24px",
              "&:before": {
                content: "''",
                position: "absolute",
                width: 0,
                height: 0,
                borderWidth: "10px",
                top: "9px",
                left: "-18px",

                border: "21px solid transparent",
                borderTopColor: "#FFFFFF",
                borderRight: "0px solid",
                marginTop: "-18px",
                transform: "rotate(42deg)",
              },
            }}
          >
            <Typography color="#000000" component="figcaption">
              {t("Write a product description for our product")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "-55px",
        }}
      >
        <HumanCard
          position="right"
          image={AIFace3}
          alt={t(
            "A happy blogger who uses Junia AI to create images for his blog"
          )}
          text={t("Create an image for my blog post")}
        />
      </Box>
    </Box>
  );
};

const PurpleLinePointer1 = ({ sx = {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      viewBox="0 0 1516 1887"
      fill="none"
      style={sx}
    >
      <path
        d="M1515.5 17.0045L0 17.0053"
        stroke="#BF32D6"
        strokeWidth="32.587"
      />
      <line
        x1="548.706"
        y1="1816.12"
        x2="548.706"
        y2="23.1172"
        stroke="#BF32D6"
        strokeWidth="32.587"
      />
      <circle cx="549" cy="1832" r="55" fill="#BF32D6" />
    </svg>
  );
};

const SecondSection1 = () => {
  const { t } = useTranslation("front-page");
  return (
    <Box
      sx={{
        position: "absolute",
        top: "-180px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "6px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
          }}
          fontWeight={700}
          component="strong"
          variant="body1"
          color="text.white"
        >
          {t("AI writer")}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1.3,
            maxWidth: "250px",
          }}
          color="#BCBCBC"
        >
          {t("Generate comprehensive people-first content in minutes")}
        </Typography>
        <PurpleLinePointer1
          sx={{
            zIndex: 2,
          }}
        />
      </Box>
    </Box>
  );
};

const SecondSection2 = () => {
  const { t } = useTranslation("front-page");

  return (
    <Box
      sx={{
        position: "absolute",
        top: "-100px",
        left: "30%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "6px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
          }}
          fontWeight={700}
          component="strong"
          variant="body1"
          color="text.white"
        >
          {t("AI images")}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1.3,
            maxWidth: "250px",
          }}
          color="#BCBCBC"
        >
          Auto-generate irresistible, high CTR images for your blog
        </Typography>
        <PurpleLinePointer1
          sx={{
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  );
};

const SecondSection3 = () => {
  const { t } = useTranslation("front-page");

  return (
    <Box
      sx={{
        position: "absolute",
        top: "-170px",
        right: "0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "6px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
          }}
          fontWeight={700}
          component="strong"
          variant="body1"
          color="text.white"
        >
          {t("Auto SEO Research")}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1.3,
            maxWidth: "250px",
          }}
          color="#BCBCBC"
        >
          {t(
            "Create outlines based on what's working for your competitors and optimize your content for search engines"
          )}
        </Typography>
        <PurpleLinePointer1
          sx={{
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  );
};

const SecondSection4 = () => {
  const { t } = useTranslation("front-page");

  return (
    <Box
      sx={{
        background: "rgba(15,23,42,.9)",
        position: "absolute",
        padding: "16px",
        display: "flex",
        alignItems: "center",
        borderRadius: "15px",
        top: "50%",
        zIndex: 3,
        left: "-100px",
        columnGap: "16px",
      }}
    >
      <Image
        src={writeLogo}
        height={50}
        width={55}
        alt={
          "A pencil on top of a piece of paper, full of text, symbolizing writing long-form content."
        }
      ></Image>
      <Typography
        sx={{
          maxWidth: "200px",
          textAlign: "left",
        }}
      >
        <Typography
          fontWeight={600}
          component="strong"
          sx={{
            color: "text.white",
            fontSize: "16px",
          }}
        >
          {t("Write 3000+ word long form content")}
        </Typography>
      </Typography>
    </Box>
  );
};

const SecondSection = () => {
  const { t } = useTranslation("front-page");

  return (
    <Box
      component="section"
      sx={{
        padding: "70px 0",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "flex",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              zIndex: 2,
              width: "100%",
              "& > img": {
                height: "100%",
                width: "100%",
              },
            }}
          >
            <Image
              alt="Screenshot of the Junia.ai content creation software's user interface, highlighting its capabilities for fast and efficient content generation. The screen features an AI writer and SEO score panel with an 85% rating in the top 2% tier, signifying effective search engine optimization. Notable features include producing 99% human-like content that is plagiarism-free, keyword analysis, and tools for drafting outlines and generating blog posts. A central image of a cup emanating a glowing, fiery effect symbolizes the creative process of content generation, set against a minimalist background with an 'AI is generating...' message below."
              src={JuniaBlog}
              placeholder="blur"
              height={700}
              width={1000}
            />
          </Box>
          <SecondSection1 />
          <SecondSection2 />
          <SecondSection3 />
          <SecondSection4 />
        </Box>
        <Box
          component={Image}
          src={JuniaBlogMobile}
          placeholder="blur"
          height={700}
          width={700}
          alt="Screenshot of an AI content creation tool interface featuring AI writer, AI images, and Auto SEO Research. The UI showcases features such as generating comprehensive people-first content quickly, crafting compelling, high click-through rate images for blogs and optimizing content for search engines with an 85% SEO score gauge. It showcases a vibrant graphic of a cup with a dynamic, glowing content-creation effect. Key attributes include a top 2% SEO score indicator, assurances of 99% human-like and plagiarism-free content, functionalities for keyword analysis, and tools for creating outlines and writing long-form content based on competitive ranking keywords."
          sx={{
            width: "100%",
            height: "100%",
            display: {
              xs: "flex",
              sm: "flex",
              md: "flex",
              lg: "none",
            },
          }}
        />
      </Container>
    </Box>
  );
};

const OverlapIcon = ({ icon, jsxIcon, noIconBg, sx = {}, reverse, index }) => {
  const Icon = icon;

  if (reverse) {
    return (
      <Box
        sx={{
          position: "relative",
          ...sx,
        }}
      >
        <Avatar
          sx={{
            width: "45px",
            height: "45px",

            background: "#CF24BE",
            ...(noIconBg ? { background: "transparent" } : {}),
          }}
        >
          <Box />
        </Avatar>
        <Avatar
          sx={{
            width: "45px",
            height: "45px",
            background: "transparent",
            bottom: "-15px",
            left: "-12px",
            position: "absolute",
            "&:after": {
              content: "''",
              background: "#F5F5F5",

              opacity: 0.4,
              position: "absolute",
              width: "100%",
              height: "100%",
            },
          }}
        >
          <Icon
            sx={{
              zIndex: 2,
              width: "17px",
              height: "17px",
              fill: "white",
            }}
          />
        </Avatar>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        ...sx,
      }}
    >
      <Avatar
        sx={{
          width: "45px",
          height: "45px",
          position: "relative",
          background: "#CF24BE",
          ...(noIconBg ? { background: "transparent" } : {}),
        }}
      >
        {jsxIcon || index}
      </Avatar>
    </Box>
  );
};

const ThirdSectionSection = ({
  jsxIcon,
  icon,
  title,
  description,
  isLast,
  index,
  noIconBg,
  titleProps = {},
}) => {
  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        columnGap: "48px",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <OverlapIcon
          noIconBg={noIconBg}
          jsxIcon={jsxIcon}
          icon={icon}
          index={index}
        />
        <Box
          sx={{
            height: "50px",
            width: "1px",
            borderLeft: isLast ? 0 : "1px dashed #b59fd0",
            marginTop: "20px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "12px",
          marginTop: "-6px",
        }}
      >
        <ResponsiveText
          color="text.white"
          fontSize={24}
          decrement="medium"
          {...titleProps}
          sx={{
            fontWeight: 700,
            ...(titleProps.sx || {}),
          }}
        >
          {title}
        </ResponsiveText>
        <ResponsiveText
          fontSize={18}
          decrement="medium"
          sx={{
            maxWidth: "400px",
          }}
          fontWeight={500}
          color="#BCBCBC"
        >
          {description}
        </ResponsiveText>
      </Box>
    </Box>
  );
};

const ThirdSection = () => {
  const { t } = useTranslation("front-page");
  return (
    <Box
      component="section"
      sx={{
        padding: {
          xs: "70px 0",
          sm: "70px 0",
          md: "70px 0",
          lg: "124px 0",
        },
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            alignItems: {
              xs: "center",
              sm: "center",
              md: "center",
              lg: "flex-start",
            },
            padding: "0 12px",
            justifyContent: "space-between",
            flexDirection: {
              xs: "column-reverse",
              sm: "column-reverse",
              md: "column-reverse",
              lg: "row-reverse",
            },
          }}
        >
          <Box
            component="ul"
            sx={{
              padding: 0,
              margin: 0,
              display: "flex",
              flexDirection: "column",
              rowGap: "36px",
            }}
          >
            <ThirdSectionSection
              icon={CreateOutlinedIcon}
              index={1}
              title={t("One-Shot Long Article Creation")}
              description={t(
                "With just one click, our AI tackles keyword research, long-form content, images and SEO meta data."
              )}
            />
            <ThirdSectionSection
              index={2}
              icon={AttachMoneyOutlinedIcon}
              title={t("End The Backlink Struggle")}
              description={t(
                "Make up for lack of backlinks with top-notch, AI-written content."
              )}
            />
            <ThirdSectionSection
              index={3}
              icon={QueryBuilderOutlinedIcon}
              title={t("Profit from Your Traffic")}
              isLast
              description={t(
                "Increase conversion rates, attract quality traffic consistently with higher SEO ranking."
              )}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "12px",
              marginBottom: {
                xs: "70px",
                sm: "70px",
                md: "70px",
                lg: "0",
              },
            }}
          >
            <ResponsiveText
              color="text.white"
              variant="h2"
              fontSize={55}
              decrement="large"
              sx={{
                maxWidth: "500px",
              }}
            >
              {t("Rank Higher with Long-form Content")}
            </ResponsiveText>
            {isCn() ? (
              <ResponsiveText
                fontSize={20}
                decrement="medium"
                sx={{
                  maxWidth: "400px",
                }}
                color="#BCBCBC"
              >
                立即使用顶尖的AI写作工具，从本周开始为您的网站吸引更多流量。
              </ResponsiveText>
            ) : (
              <ResponsiveText
                fontSize={20}
                decrement="medium"
                sx={{
                  maxWidth: "400px",
                }}
                color="#BCBCBC"
              >
                <InlineSpan inherit>
                  Drive more traffic to your website with truly quality content,
                  starting from{" "}
                </InlineSpan>
                <HighlightedSpan
                  inherit
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  this week
                </HighlightedSpan>
                <InlineSpan inherit>.</InlineSpan>
              </ResponsiveText>
            )}

            <HighlightedCallToAction
              rootProps={{
                sx: {
                  maxWidth: "390px",
                  margin: "12px auto 0 0",
                },
              }}
            >
              {t("Get More Traffic With Long-Form Content")}
            </HighlightedCallToAction>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const FourthSectionSection = ({ icon, to, title, description }) => {
  const Icon = icon;

  return (
    <Box
      component="li"
      sx={{
        width: "100%",
      }}
    >
      <Button
        component={NextLink}
        capitalize
        target="__blank"
        href={to}
        variant="contained"
        sx={{
          display: "flex",
          textDecoration: "none",
          width: {
            xs: "100%",
            sm: "100%",
            md: "500px",
            lg: "500px",
          },
          justifyContent: "space-between",
          alignItems: "center",
          background: "white",
          borderRadius: "15px",
          padding: "24px",
          margin: "auto",
          "&:hover": {
            background: "white",
            opacity: 0.8,
          },
          "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "24px",
          }}
        >
          <Avatar
            sx={{
              background: "rgba(54, 15, 103, 0.25)",
            }}
          >
            <Icon
              sx={{
                fill: "#360F67",
              }}
            />
          </Avatar>
          <Box>
            <Typography variant="body2" color="#747474">
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
              }}
              fontWeight={600}
              variant="body1"
              color="#000000"
            >
              {description}
            </Typography>
          </Box>
        </Box>
        <ChevronRightOutlinedIcon
          sx={{
            fill: "initial",
          }}
        />
      </Button>
    </Box>
  );
};

const FourthSection = () => {
  const { t } = useTranslation("front-page");
  return null;
  return (
    <Box
      component="section"
      sx={{
        padding: "70px 0 124px 0",
      }}
    >
      <Container maxWidth="lg">
        <Box
          component="ul"
          sx={{
            margin: 0,
            padding: "0 12px",
            listStyle: "none",
            display: "flex",
            flexDirection: "column",
            rowGap: "24px",
            alignItems: "center",
          }}
        >
          <FourthSectionSection
            icon={HistoryEduIcon}
            to={templateServerRouteBySlug("blog")}
            title={t("For Bloggers")}
            description={t("Write Blogs 10X faster")}
          />
          <FourthSectionSection
            icon={StorefrontIcon}
            to={templateServerRouteBySlug("product-promotion")}
            title={t("For Marketers")}
            description={t("Create higher-converting ad copy")}
          />
          <FourthSectionSection
            icon={DraftsIcon}
            to={templateServerRouteBySlug("recurring-email-email")}
            title={t("For Email marketers")}
            description={t("Boost Email Engagement")}
          />
          <FourthSectionSection
            icon={LocalSeeIcon}
            to={templateServerRouteBySlug("instagram-post")}
            title={t("For Content Creators")}
            description={t("Produce more engaging content")}
          />
        </Box>
      </Container>
    </Box>
  );
};

const FithSection = () => {
  const { t } = useTranslation("front-page");

  return (
    <Box
      component="section"
      sx={{
        padding: "124px 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "24px",
            padding: {
              xs: "0 24px",
              sm: "0 24px",
              md: "0 0 0 20%",
              lg: "0 0 0 20%",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            <ResponsiveText
              sx={{
                zIndex: 1,
                position: "relative",
                maxWidth: "600px",
              }}
              fontSize={50}
              decrement="medium"
              color="text.white"
              variant="h3"
            >
              {t("More than 50+ AI Writing Templates to choose from")}
            </ResponsiveText>
            <Box
              sx={{
                position: "absolute",
                background:
                  "linear-gradient(180deg, rgba(24, 75, 255, 0) 0%, rgba(23, 74, 255, 0.6) 100%)",
                height: "200px",
                width: "200px",
                filter: "blur(42px)",
                opacity: 0.6,
                top: 0,
                transform: "rotate(44.76deg)",
              }}
            />
          </Box>
          <ResponsiveText
            sx={{
              zIndex: 1,
              maxWidth: "400px",
            }}
            fontSize={22}
            decrement="medium"
            color="text.white"
          >
            {t(
              "Streamline brainstorming, outlining, drafting, and editing for blogs and more."
            )}
          </ResponsiveText>
          <Box>
            <Button
              variant="contained"
              capitalize
              component="a"
              target="__blank"
              href={serverRoutes.templates}
              sx={{
                backgroundColor: "#604088",
                color: "white",
                borderRadius: "10px",
                padding: "12px 24px",
                fontSize: "18px",
                "&:hover": {
                  backgroundColor: "#604088",
                  opacity: 0.8,
                },
              }}
              endIcon={<ChevronRightOutlinedIcon />}
            >
              <ResponsiveText fontSize={18} decrement="medium">
                {t("See Templates")}
              </ResponsiveText>
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "initial",
            },
          }}
        >
          <Image
            src={ContentTypes}
            alt={t(
              "Four squares with the words blog post, product description, and resume written on them."
            )}
            height={500}
            width={500}
            style={{
              width: "100%",
              height: "100%",
            }}
          ></Image>
        </Box>
      </Box>
    </Box>
  );
};

const SithSectionSection = ({ text, icon }) => {
  const Icon = icon;

  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "12px",
      }}
    >
      <Icon
        sx={{
          height: "60px",
          width: "60px",
          fill: "#FFC107",
        }}
      />
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: 600,
        }}
        color={"#5DADE2"}
      >
        {text}
      </Typography>
    </Box>
  );
};

const SithSection = () => {
  const { t } = useTranslation("front-page");
  return (
    <Box
      component="section"
      sx={{
        padding: "0 0 170px 0",
      }}
    >
      <Container maxWidth="lg">
        <ResponsiveText
          variant="h4"
          fontSize={40}
          sx={{
            marginBottom: "72px",
          }}
          textAlign={"center"}
          color="text.white"
          fontWeight={600}
        >
          {t("Junia is used by")}
        </ResponsiveText>

        <Grid
          container
          component="ul"
          sx={{
            listStyle: "none",
            padding: 0,
            margin: 0,
            justifyContent: "space-around",
            rowGap: "24px",
          }}
        >
          <Grid xs={6} md={3} lg={2}>
            <SithSectionSection
              icon={AutoGraphOutlinedIcon}
              text={t("Marketers")}
            />
          </Grid>
          <Grid xs={6} md={3} lg={2}>
            <SithSectionSection
              icon={LightbulbOutlinedIcon}
              text={t("Entrepreneurs")}
            />
          </Grid>
          <Grid xs={6} md={3} lg={2}>
            <SithSectionSection
              icon={SchoolOutlinedIcon}
              text={t("Students")}
            />
          </Grid>
          <Grid xs={6} md={3} lg={2}>
            <SithSectionSection
              icon={ContentPasteOutlinedIcon}
              text={t("Content creators")}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const JuniaChatSectionSection = ({ text, description, icon }) => {
  return (
    <Box component="li">
      <Box
        component="article"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          rowGap: "24px",
        }}
      >
        <OverlapIcon reverse icon={icon || CreateOutlinedIcon} sx={{}} />
        <ResponsiveText
          fontSize={20}
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            color: "#000000",
          }}
        >
          {text}
        </ResponsiveText>
        <ResponsiveText
          fontSize={16}
          sx={{
            maxWidth: "400px",
          }}
          color="#454545"
        >
          {description}
        </ResponsiveText>
      </Box>
    </Box>
  );
};

const JuniaChatSection = () => {
  const { t } = useTranslation("front-page");
  return (
    <Box component="section" sx={{}}>
      <Container maxWidth="lg">
        <FeatureItem
          type="image"
          imageUrl={JuniaChat}
          useCheckMark={false}
          imageBeforeTitleOnMobile
          imageAlt={t("Junia AI Chat User Interface")}
          headlineProps={{
            fontSize: 40,
            sx: {
              color: "initial",
              fontWeight: 600,
            },
          }}
          contentProps={{
            sx: {
              color: "initial",
              fontWeight: 400,
            },
          }}
          headline={t("Meet Junia chat, the AI chatbot that outsmarts chatgpt")}
          content={[
            t("junia-chat-section-1"),
            t("junia-chat-section-2"),
            t("junia-chat-section-3"),
          ]}
          slot={{
            end: (
              <Box>
                <Button
                  capitalize
                  variant="contained"
                  component={NextLink}
                  href={serverRoutes.register}
                  sx={{
                    marginTop: "24px",
                    borderRadius: "22px",
                    padding: "12px 36px",
                    background: "#CF24BE",
                    fontSize: "16px",
                    "&:hover": {
                      background: "#CF24BE",
                      opacity: 0.8,
                    },
                  }}
                >
                  {t("Try For Free!")}
                </Button>
              </Box>
            ),
          }}
        />

        <Box
          component="section"
          sx={{
            padding: "70px 0 124px 0",
          }}
        >
          <ResponsiveText
            variant="h5"
            fontSize={20}
            sx={{
              color: "#BFBFBF",
              marginBottom: "48px",
              textAlign: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "left",
              },
            }}
          >
            {t("FEATURES OF JUNIA CHAT")}
          </ResponsiveText>

          <Grid
            container
            component="ul"
            columnSpacing={"48px"}
            rowSpacing={"48px"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              margin: 0,
              listStyle: "none",
              padding: 0,
            }}
          >
            <Grid xs={12} sm={7} md={7} lg={4}>
              <JuniaChatSectionSection
                text={t("Real time google search")}
                description={t(
                  "Get instant answers to your questions with real-time Google search feature. No need to leave the chat - just ask and receive accurate information in seconds."
                )}
              />
            </Grid>
            <Grid xs={12} sm={7} md={7} lg={4}>
              <JuniaChatSectionSection
                text={t("Voice command")}
                icon={MicOutlinedIcon}
                description={t(
                  "Tired of typing? Use voice command feature to ask questions using your voice, no typing required! Get answers, insights, and assistance without lifting a finger."
                )}
              />
            </Grid>
            <Grid xs={12} sm={7} md={7} lg={4}>
              <JuniaChatSectionSection
                icon={CloudUploadOutlinedIcon}
                text={t("File and Link Analysis")}
                description={t(
                  "You can easily upload files or links for analysis and receive helpful insights on any topic. Simply upload your file or share a link, and let our AI do the rest! Get accurate information and insights in seconds."
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

const Hero = React.memo(() => {
  const { t } = useTranslation("front-page");
  const mainHero: Record<string, any> = t("main-hero", { returnObjects: true });
  return (
    <Box
      component="section"
      sx={{
        textAlign: "center",
        position: "relative",
        padding: "30px 0 0 0",
        minHeight: {
          xs: "initial",
          sm: "initial",
          md: "initial",
          xl: `calc(100vh - ${headerHeight}px)`,
        },
        padding: {
          xs: "30px 0 0 0",
          sm: "30px 0 0 0",
          md: "30px 0 0 0",
          lg: "30px 0 180px 0",
        },
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          position: "relative",
          marginTop: "24px",
        }}
      >
        <Stars />
      </Container>
      <Container
        maxWidth="xl"
        sx={{
          position: "relative",
          marginTop: {
            xs: `24px`,
            sm: `24px`,
            md: `calc((100vh - ${headerHeight}px) / 10)`,
            xl: `calc((100vh - ${headerHeight}px) / 10)`,
          },
        }}
      >
        <Typography
          variant="h1"
          fontWeight={500}
          sx={{
            fontSize: {
              xs: "2.4rem",
              sm: `2.5rem`,
              md: `3rem`,
              xl: `3.5rem`,
              lg: "4rem",
            },
            "& > span": {
              fontSize: {
                xs: "2.4rem",
                sm: `2.5rem`,
                md: `3rem`,
                xl: `3.5rem`,
                lg: "4rem",
              },
            },
            color: "text.white",
            textAlign: "center",
            margin: "auto",
            maxWidth: "850px",
            lineHeight: 1.2,
            width: {
              xs: "100%",
              sm: `100%`,
              md: `100%`,
              xl: `100%`,
              lg: "100%",
            },
          }}
        >
          Generate High Ranking Content and Drive Traffic on Autopilot
          {/*
           <>
            <Trans t={t} i18nKey="headline-text">
              <InlineSpan inherit></InlineSpan>
              <InlineSpan
                inherit
                sx={{
                  color: "#E910D3",
                }}
              ></InlineSpan>
              <InlineSpan inherit></InlineSpan>
            </Trans>
          </>
          */}
        </Typography>

        {isCn() ? (
          <ResponsiveText
            color="text.white"
            variant="body2"
            fontSize={22}
            textAlign={"left"}
            sx={{
              marginTop: "48px",
              maxWidth: "600px",
              fontWeight: 400,
            }}
          >
            <InlineSpan inherit>创作</InlineSpan>
            <InlineSpan inherit component="strong">
              独一无二、详尽、长篇幅
            </InlineSpan>
            <InlineSpan inherit>且</InlineSpan>
            <InlineSpan inherit component="strong">
              无抄袭
            </InlineSpan>
            <InlineSpan inherit>的内容</InlineSpan>
            <InlineSpan inherit>- 解决你的</InlineSpan>
            <InlineSpan inherit component="strong">
              SEO难题
            </InlineSpan>
            <InlineSpan inherit>。</InlineSpan>
          </ResponsiveText>
        ) : (
          <ResponsiveText
            color="#BCBCBC"
            variant="body2"
            fontSize={22}
            textAlign={"center"}
            sx={{
              margin: "24px auto 0 auto",
              maxWidth: "800px",
              fontWeight: 400,
            }}
          >
            {/*
              Elevate your site's SEO with Junia AI. Drive more organic traffic, improve visibility, and climb rankings with quality, SEO-optimized content.
              */}
            <InlineSpan inherit>
              Elevate your site's SEO with Junia AI. Drive more{" "}
            </InlineSpan>
            <InlineSpan
              inherit
              component="strong"
              sx={{
                fontWeight: 700,
              }}
            >
              organic traffic
            </InlineSpan>
            <InlineSpan inherit>, improve visibility, and </InlineSpan>
            <InlineSpan
              inherit
              component="strong"
              sx={{
                fontWeight: 700,
              }}
            >
              climb rankings
            </InlineSpan>
            <InlineSpan inherit> with quality, SEO-optimized, and </InlineSpan>
            <InlineSpan
              inherit
              component="strong"
              sx={{
                fontWeight: 700,
              }}
            >
              ready-to-rank
            </InlineSpan>
            <InlineSpan inherit> content.</InlineSpan>
          </ResponsiveText>
        )}
        {/*
         <Box
          sx={{
            marginTop: "24px",
            display: "flex",
            alignItems: "flex-end",
            columnGap: "8px",
          }}
        >
          <WriterList showAltText />
          <ResponsiveText
            fontSize={14}
            sx={{
              textAlign: "left",
              color: "text.white",
            }}
          >
            {t("68,362+ blog posts written.")}
          </ResponsiveText>
        </Box>
        */}
        <HighlightedCallToAction
          rootProps={{
            sx: {
              margin: "48px auto 48px auto",
              maxWidth: "400px",
              padding: "12px 6px 12px 12px",
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            },
          }}
        >
          {t("Get Your First High-Ranking Blog Post")}
        </HighlightedCallToAction>
        <HumanCardLists />
        <Box
          sx={{
            marginTop: "24px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            columnGap: "8px",
          }}
        >
          <WriterList showAltText />
          <ResponsiveText
            fontSize={14}
            sx={{
              textAlign: "left",
              color: "text.white",
            }}
          >
            {"2,000,000+ articles generated."}
          </ResponsiveText>
        </Box>
      </Container>
    </Box>
  );
});

const MainHero = React.memo(() => {
  return <Hero />;
});

const ScriptRenderer = React.memo(() => {
  const { t } = useTranslation("front-page");
  const faqLists = useFaqList();
  return (
    <>
      <ReviewMarkup />
      <Script
        type="application/ld+json"
        strategy="beforeInteractive"
        id="junia-ai-faq"
        key="junia-ai-faq"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(buildJsonLd(faqLists)),
        }}
      />
      <Script
        type="application/ld+json"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "VideoObject",
            name: "Keyword Research For Blog Posts Generation with Junia AI: Matching Customer Search Intents",
            description:
              "Video demonstrating how users employ Junia AI's advanced features to conduct detailed keyword research for their blog posts. The aim is to better match the search intents of potential customers, thereby increasing site traffic and engagement.",
            thumbnailUrl: "https://www.junia.ai/chat-1-thumbnail.png",
            uploadDate: "2023-09-10",
            contentUrl: "https://www.junia.ai/chat1.mp4",
            embedUrl: "https://www.junia.ai/chat1.mp4",
            duration: "PT12S",
          }),
        }}
      />
    </>
  );
});

const Struggle = React.memo(() => {
  const { t } = useTranslation("front-page");
  return (
    <Box
      component="section"
      sx={{
        padding: {
          xs: "70px 0",
          sm: "70px 0",
          md: "70px 0",
          lg: "124px 0",
        },
      }}
    >
      <Container maxWidth="xl">
        <ResponsiveText
          color="text.white"
          variant="h2"
          fontSize={55}
          sx={{
            textAlign: "center",
            maxWidth: "800px",
            margin: "auto",
          }}
          decrement="large"
        >
          {t("Struggling to Get Traffic to Your Blog?")}
        </ResponsiveText>
        <ResponsiveText
          fontSize={20}
          sx={{
            maxWidth: "700px",
            margin: " 12px auto 0 auto",
            textAlign: "center",
          }}
          decrement="medium"
          color="#BCBCBC"
        >
          {t(
            "You work hard on your blog, but search engines aren't showing it. You're not alone - we've been there too."
          )}
        </ResponsiveText>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "72px",
          }}
        >
          <Box
            component="ul"
            sx={{
              padding: 0,
              margin: 0,
              display: "flex",
              flexDirection: "column",
              rowGap: "36px",
            }}
          >
            <ThirdSectionSection
              jsxIcon={
                <CheckOutlinedIcon
                  sx={{
                    fill: "white",
                  }}
                />
              }
              titleProps={{
                sx: {
                  fontWeight: 500,
                },
              }}
              title={t("You invest in other AI writing tools")}
              description={
                isCn() ? (
                  <InlineSpan inherit>
                    <InlineSpan inherit>您相信他们能够提供优化了</InlineSpan>{" "}
                    <InlineSpan inherit component="s">
                      优化了
                    </InlineSpan>{" "}
                    <InlineSpan inherit> 的博客文章.</InlineSpan>
                  </InlineSpan>
                ) : (
                  <InlineSpan inherit>
                    <InlineSpan inherit>You trust them to deliver</InlineSpan>{" "}
                    <InlineSpan inherit component="s">
                      SEO-optimized
                    </InlineSpan>{" "}
                    <InlineSpan inherit> blog posts.</InlineSpan>
                  </InlineSpan>
                )
              }
            />
            <ThirdSectionSection
              jsxIcon={
                <CheckOutlinedIcon
                  sx={{
                    fill: "white",
                  }}
                />
              }
              titleProps={{
                sx: {
                  fontWeight: 500,
                },
              }}
              title={t("You publish your first post")}
              description={t("Expecting a flood of traffic and engagement.")}
            />
            <ThirdSectionSection
              noIconBg
              jsxIcon={
                <CloseOutlinedIcon
                  sx={{
                    fill: "#FF0000",
                    height: "50px",
                    width: "50px",
                  }}
                />
              }
              titleProps={{
                sx: {
                  fontWeight: 800,
                },
              }}
              title={t("You get 0 traffic to your blog")}
              isLast
              description={t(
                "Despite your efforts, search engines just don't notice. Money and time wasted."
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "24px",
          }}
        >
          <Button
            capitalize
            variant="contained"
            component={NextLink}
            href={serverRoutes.register}
            sx={{
              marginTop: "12px",
              borderRadius: "22px",
              padding: "12px 36px",
              background: "#CF24BE",
              fontSize: "20px",
              fontWeight: 500,
              "&:hover": {
                background: "#CF24BE",
                opacity: 0.8,
              },
            }}
          >
            <ResponsiveText fontSize={20} decrement="medium">
              {t("End SEO Struggles")}
            </ResponsiveText>
          </Button>
        </Box>
      </Container>
    </Box>
  );
});

export default function IndexPage({ seoBot }: { seoBot: boolean }) {
  const { t } = useTranslation("front-page");

  return (
    <>
      <IndexPageHeadTags />
      <ScriptRenderer />

      <Box
        sx={{
          background:
            "linear-gradient(177.9deg, #111827 0%, #360F67 50%, #360F67 100%)",
        }}
      >
        <MainHero />
        <SecondSection />
        <ThirdSection />
        <Testmonial />
        <Struggle />
        <Features />
        <FourthSection />
        <Amazing />
        <FithSection />
      </Box>
      {/*
        <MoreFeatureList />
        */}
      {/*
        <JuniaChatSection />
      <Box
        sx={{
          background:
            "linear-gradient(177.9deg, #111827 0%, #360F67 50%, #360F67 100%)",
        }}
      >
        <JuniaArtSection />
        <SithSection />
      </Box>

    */}
      <Box
        sx={{
          background: "#111827",
        }}
      >
        <HowItWorks
          sx={{
            background: "inherit",
          }}
        />
      </Box>

      <ReadyToLevelUp
        title={t("Ready To Win the SEO Game?")}
        titleProps={{
          sx: {
            maxWidth: "800px",
            margin: "auto auto 12px auto",
            lineHeight: 1.2,
          },
        }}
        callToAction={t("Boost Your SEO with AI")}
        description={t(
          "Say goodbye to endless writing with no SEO gain. With the #1 AI Writer for SEO, write faster and get more clicks on your blogs."
        )}
      />
      <Faq />
      {seoBot && (
        <div>
          <a href="https://climate.stripe.com/7doPyW">Junia Stripe climate</a>
          <a href="https://apps.shopify.com/junia-ai">Junia Shopify</a>
          <a href="https://www.amazon.com/Todoer-best-todo-ever-need/dp/B0852QZG88/">
            todo
          </a>
          <a href="https://www.adgully.com/how-to-leverage-ai-to-enhance-your-digital-marketing-posts-143524.html">
            ad
          </a>
          <a href="https://www.tribuneindia.com/news/impact-feature/best-5-ai-article-writers-569000/">
            India
          </a>
          <a href="https://markets.businessinsider.com/news/stocks/junia-ai-recognized-as-one-of-the-fastest-growing-ai-writing-platforms-in-2024-1032927542">
            Insider
          </a>
        </div>
      )}
      {/*
        <ContentCreation />
      <AiArt />
      <JuniaChat />
      <ImageRestorer />
      <AboutJunia />
      <PricingSection />
      <Faq />
  */}
    </>
  );
}

export async function getStaticProps() {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["front-page", "common"])),
      // Will be passed to the page component as props
    },
  };
}

/*
export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const userAgent = context.req.headers["user-agent"];
  let seoBot = false;
  if (
    userAgent &&
    (userAgent.toLowerCase().includes("ahrefsbot") ||
      userAgent.toLowerCase().includes("semrushbot"))
  ) {
    seoBot = true;
  }
  return {
    props: {
      seoBot,
      ...(await serverSideTranslations(locale, ["front-page", "common"])),
    },
  };
};
*/
